import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/admin.vue'


Vue.use(VueRouter);


export const constantRoutes = [
  // {
  //     path: '/',
  //     redirect: '/login',
  //     permit: 0
  // },
  {
      path: '/',
      redirect: '/index',
      permit: 0
  },
  {
    path: '/login',
    name: "login",
    permit: 0,
    component: () =>
      import('@/views/Login.vue'),
  },
  // {
  //     icon: "icon-shouye2",
  //     path: "/index",
  //     name: "index",
  //     web_name: "首页",
  //     permit: 1,
  //     component: () => import('@/views/index.vue'),
  //     meta: { title: '首页' }
  // },
  {
    path: '/index',
    icon: "icon-shouye2",
    web_name: "首页",
    component: Layout,
    redirect: '/index',
    permit: 1,
    children: [
      {
        path: '/index',
        name: 'index',
        web_name: "首页",
        permit: 1,
        component: () => import('@/views/index/index'),
        icon: "icon-shouye2",
        meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true },
        // component: (resolve) => require(['@/views/home/index'], resolve)
      }
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/user',
    component: Layout,
    permit: 1,
    web_name: '用户管理',
    children: [
      {
        path: '/user/index',
        permit: 1,
        web_name: '用户列表',
        component: (resolve) => require(['@/views/user/index/index'], resolve),
        meta: { title: '用户列表' },
      },
      {
        path: '/user/shareholder',
        permit: 1,
        component: (resolve) => require(['@/views/user/shareholder/index'], resolve),
        web_name: '股东列表',
        meta: { title: '股东列表' },
      },
      {
        path: '/user/captain',
        permit: 1,
        web_name: '队长列表',
        component: (resolve) => require(['@/views/user/captain/index'], resolve),
        meta: { title: '队长列表' },
      },
      {
        path: '/user/partner',
        permit: 1,
        web_name: '合伙人列表',
        component: (resolve) => require(['@/views/user/partner/index'], resolve),
        meta: { title: '合伙人列表' },
      },
      {
        path: '/user/partner-contract',
        permit: 1,
        web_name: '合同管理',
        component: (resolve) => require(['@/views/user/partner-contract/index'], resolve),
        meta: { title: '合同管理' },
      },
      {
        path: '/user/partner-withdrawal',
        permit: 1,
        web_name: '加入明细',
        component: (resolve) => require(['@/views/user/partner-withdrawal/index'], resolve),
        meta: { title: '加入明细' },
      },
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/recruitment',
    component: Layout,
    permit: 1,
    web_name: '勤工俭学',
    children: [
      // {
      //   path: '/recruitment/ride-template',
      //   permit: 1,
      //   // component: () => import('@/views/recruitment/ride-template/index'),
      //   component: (resolve) => require(['@/views/recruitment/ride-template'], resolve),
      //   web_name: '乘车信息模板管理',
      //   meta: { title: '乘车信息模板管理' },
      // },
      {
        path: '/recruitment/job/config',
        permit: 1,
        // component: () => import('@/views/recruitment/job/config/index'),
        component: (resolve) => require(['@/views/recruitment/job/config'], resolve),
        web_name: '配置管理',
        meta: { title: '配置管理' },
      },
      {
        path: '/recruitment/company/index',
        permit: 1,
        web_name: '公司管理',
        component: (resolve) => require(['@/views/recruitment/company'], resolve),
        meta: { title: '公司管理' },
      },
      {
        path: '/recruitment/job/index',
        permit: 1,
        web_name: '职位管理',
        component: (resolve) => require(['@/views/recruitment/job/index'], resolve),
        meta: { title: '职位管理' },
      },
      {
        name: '/recruitment/registration',
        path: '/recruitment/registration',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/registration/index.vue'], resolve),
        web_name: '报名列表',
        meta: { title: '报名列表' },
      },
      // {
      //   name: '/recruitment/wait-pay',
      //   path: '/recruitment/wait-pay',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/recruitment/wait-pay/index.vue'], resolve),
      //   web_name: '待付列表',
      //   meta: { title: '待付列表' },
      // },
      // {
      //   name: '/recruitment/paid',
      //   path: '/recruitment/paid',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/recruitment/paid/index.vue'], resolve),
      //   web_name: '已付列表',
      //   meta: { title: '已付列表' },
      // },
      // {
      //   name: '/recruitment/paid-info',
      //   path: '/recruitment/paid/info',
      //   permit: 0,
      //   component: (resolve) => require(['@/views/recruitment/paid/info.vue'], resolve),
      //   web_name: '已支付详情',
      //   meta: { title: '已支付详情' },
      // },
      // {
      //   name: '/recruitment/refund',
      //   path: '/recruitment/refund',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/recruitment/refund/index.vue'], resolve),
      //   web_name: '退款列表',
      //   meta: { title: '退款列表' }
      // },
      {
        name: '/recruitment/ride',
        path: '/recruitment/ride',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/ride/index.vue'], resolve),
        web_name: '乘车管理',
        meta: { title: '乘车管理' }
      },
      {
        name: '/recruitment/attendance',
        path: '/recruitment/attendance',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/attendance/index.vue'], resolve),
        web_name: '到场管理',
        meta: { title: '到场管理' }
      },
      {
        name: '/recruitment/interview/have',
        path: '/recruitment/interview/have',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/interview/have/index.vue'], resolve),
        web_name: '面试管理',
        meta: { title: '面试管理' }
      },
      {
        name: '/recruitment/enter-post',
        path: '/recruitment/enter-post',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/enter-post/index.vue'], resolve),
        web_name: '入职管理',
        meta: { title: '入职管理' }
      },
      {
        name: '/recruitment/resigned',
        path: '/recruitment/resigned',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/resigned/index.vue'], resolve),
        web_name: '在职管理',
        meta: { title: '在职管理' }
      },
      {
        name: '/recruitment/anticipate-wages',
        path: '/recruitment/anticipate-wages',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/anticipate-wages/index.vue'], resolve),
        web_name: '借支管理',
        meta: { title: '借支管理' }
      },
      {
        name: '/recruitment/withdrawal-wages',
        path: '/recruitment/withdrawal-wages',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/withdrawal-wages/index.vue'], resolve),
        web_name: '制单管理',
        meta: { title: '制单管理' }
      },
      {
        name: '/recruitment/pay-admin',
        path: '/recruitment/pay-admin',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/pay-admin/index.vue'], resolve),
        web_name: '发薪管理',
        meta: { title: '发薪管理' }
      },
      {
        name: '/recruitment/archived',
        path: '/recruitment/archived',
        permit: 1,
        component: (resolve) => require(['@/views/recruitment/archived/index.vue'], resolve),
        web_name: '档案管理',
        meta: { title: '档案管理' }
      },
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/driver-school',
    component: Layout,
    permit: 1,
    web_name: '驾考培训',
    children: [
      {
        path: '/driver-school/config',
        permit: 1,
        web_name: '配置管理',
        component: (resolve) => require(['@/views/driver-school/config/index'], resolve),
        meta: { title: '配置管理' },
      },
      {
        path: '/driver-school/manage',
        permit: 1,
        component: (resolve) => require(['@/views/driver-school/manage/index'], resolve),
        web_name: '驾校管理',
        meta: { title: '驾校管理' },
      },
      // {
      //   path: '/driver-school/package',
      //   permit: 1,
      //   web_name: '套餐管理',
      //   component: (resolve) => require(['@/views/driver-school/package/index'], resolve),
      //   meta: { title: '套餐管理' },
      // },
      {
        path: '/driver-school/registration',
        permit: 1,
        web_name: '报名列表',
        component: (resolve) => require(['@/views/driver-school/registration/index'], resolve),
        meta: { title: '报名列表' },
      },
      {
        path: '/driver-school/data',
        permit: 1,
        web_name: '资料管理',
        component: (resolve) => require(['@/views/driver-school/data/index'], resolve),
        meta: { title: '资料管理' },
      },

  
    ]
  },
  {
    path: '/campus-mall',
    icon: "icon-shouye2",
    web_name: "校园商城",
    component: Layout,
    redirect: '/campus-mall',
    permit: 1,
    children: [
      {
        path: '/campus-mall/product',
        name: 'campus-mall/product',
        web_name: "商品管理",
        permit: 1,
        component: () => import('@/views/campus-mall/product/index'),
        icon: "icon-shouye2",
        meta: { title: '商品管理', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/check',
        name: 'campus-mall/check',
        web_name: "商品审核",
        permit: 1,
        component: () => import('@/views/campus-mall/check/index'),
        icon: "icon-shouye2",
        meta: { title: '商品审核', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/receive',
        name: 'campus-mall/receive',
        web_name: "自提订单",
        permit: 1,
        component: () => import('@/views/campus-mall/receive/index'),
        icon: "icon-shouye2",
        meta: { title: '自提订单', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/delivery-order',
        name: 'campus-mall/delivery-order',
        web_name: "快递订单",
        permit: 1,
        component: () => import('@/views/campus-mall/delivery-order/index'),
        icon: "icon-shouye2",
        meta: { title: '快递订单', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/goods-rate',
        name: 'campus-mall/goods-rate',
        web_name: "商品评价",
        permit: 1,
        component: () => import('@/views/campus-mall/goods-rate/index'),
        icon: "icon-shouye2",
        meta: { title: '商品评价', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/config',
        name: 'campus-mall/config',
        web_name: "店铺配置",
        permit: 1,
        component: () => import('@/views/campus-mall/config/index'),
        icon: "icon-shouye2",
        meta: { title: '店铺配置', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/store-list',
        name: 'campus-mall/store-list',
        web_name: "商家列表",
        permit: 1,
        component: () => import('@/views/campus-mall/store-list/index'),
        icon: "icon-shouye2",
        meta: { title: '商家列表', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/category',
        name: 'campus-mall/category',
        web_name: "商品分类",
        permit: 1,
        component: () => import('@/views/campus-mall/category/index'),
        icon: "icon-shouye2",
        meta: { title: '商品分类', icon: 'dashboard', noCache: true, affix: true },
      },
     
      {
        path: '/campus-mall/delisting-product',
        name: 'campus-mall/delisting-product',
        web_name: "下架商品",
        permit: 1,
        component: () => import('@/views/campus-mall/delisting-product/index'),
        icon: "icon-shouye2",
        meta: { title: '下架商品', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/order',
        name: 'campus-mall/order',
        web_name: "订单列表",
        permit: 1,
        component: () => import('@/views/campus-mall/order/index'),
        icon: "icon-shouye2",
        meta: { title: '订单列表', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/wait-pay',
        name: 'campus-mall/wait-pay',
        web_name: "待付列表",
        permit: 1,
        component: () => import('@/views/campus-mall/wait-pay/index'),
        icon: "icon-shouye2",
        meta: { title: '待付列表', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/paid',
        name: 'campus-mall/paid',
        web_name: "已付列表",
        permit: 1,
        component: () => import('@/views/campus-mall/paid/index'),
        icon: "icon-shouye2",
        meta: { title: '已付列表', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/delivery-wait',
        name: 'campus-mall/delivery-wait',
        web_name: "等待发货",
        permit: 1,
        component: () => import('@/views/campus-mall/delivery-wait/index'),
        icon: "icon-shouye2",
        meta: { title: '等待发货', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/delivery-success',
        name: 'campus-mall/delivery-success',
        web_name: "成功发货",
        permit: 1,
        component: () => import('@/views/campus-mall/delivery-success/index'),
        icon: "icon-shouye2",
        meta: { title: '成功发货', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/receiving',
        name: 'campus-mall/receiving',
        web_name: "已经到货",
        permit: 1,
        component: () => import('@/views/campus-mall/receiving/index'),
        icon: "icon-shouye2",
        meta: { title: '已经到货', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/order-completed',
        name: 'campus-mall/order-completed',
        web_name: "交易完成",
        permit: 1,
        component: () => import('@/views/campus-mall/order-completed/index'),
        icon: "icon-shouye2",
        meta: { title: '交易完成', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/order-after-sales',
        name: 'campus-mall/order-after-sales',
        web_name: "售后申请",
        permit: 1,
        component: () => import('@/views/campus-mall/order-after-sales/index'),
        icon: "icon-shouye2",
        meta: { title: '售后申请', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/refuse-refund',
        name: 'campus-mall/refuse-refund',
        web_name: "拒绝退款",
        permit: 1,
        component: () => import('@/views/campus-mall/refuse-refund/index'),
        icon: "icon-shouye2",
        meta: { title: '拒绝退款', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/success-refund',
        name: 'campus-mall/success-refund',
        web_name: "已经退款",
        permit: 1,
        component: () => import('@/views/campus-mall/success-refund/index'),
        icon: "icon-shouye2",
        meta: { title: '已经退款', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/campus-mall/archives',
        name: 'campus-mall/archives',
        web_name: "订单档案",
        permit: 1,
        component: () => import('@/views/campus-mall/archives/index'),
        icon: "icon-shouye2",
        meta: { title: '订单档案', icon: 'dashboard', noCache: true, affix: true },
      }
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/divide-profit',
    component: Layout,
    permit: 1,
    web_name: '分润中心',
    children: [
      {
        path: '/divide-profit/reveive-profit',
        permit: 1,
        component: (resolve) => require(['@/views/divide-profit/reveive-profit/index'], resolve),
        web_name: '自提订单分润',
        meta: { title: '自提订单分润' },
      },
    ]
  },
  {
    path: '/ticketing-for-travel',
    icon: "icon-shouye2",
    web_name: "购票出行",
    component: Layout,
    permit: 1,
    children: [
      {
        path: '/ticketing-for-travel/borading-location',
        name: 'ticketing-for-travel/borading-location',
        web_name: "上客地点",
        permit: 1,
        component: () => import('@/views/ticketing-for-travel/borading-location/index'),
        icon: "icon-shouye2",
        meta: { title: '上客地点', icon: 'dashboard', noCache: true, affix: true },
        // component: (resolve) => require(['@/views/home/index'], resolve)
      },
      {
        path: '/ticketing-for-travel/route',
        name: 'ticketing-for-travel/route',
        web_name: "路线管理",
        permit: 1,
        component: () => import('@/views/ticketing-for-travel/route/index'),
        icon: "icon-shouye2",
        meta: { title: '路线管理', icon: 'dashboard', noCache: true, affix: true },
        // component: (resolve) => require(['@/views/home/index'], resolve)
      },
      {
        path: '/ticketing-for-travel/config',
        name: 'ticketing-for-travel/config',
        web_name: "商品管理",
        permit: 1,
        component: () => import('@/views/ticketing-for-travel/config/index'),
        icon: "icon-shouye2",
        meta: { title: '出行配置', icon: 'dashboard', noCache: true, affix: true },
        // component: (resolve) => require(['@/views/home/index'], resolve)
      }
    ]
  },
  {
    path: '/education-train',
    icon: "icon-shouye2",
    web_name: "教育培训",
    component: Layout,
    permit: 1,
    children: [
      {
        path: '/education-train/category',
        name: 'education-train/category',
        web_name: "教育产品类目管理",
        permit: 1,
        component: () => import('@/views/education-train/category/index'),
        icon: "icon-shouye2",
        meta: { title: '教育产品类目管理', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/education-train/config',
        name: 'education-train/config',
        web_name: "机构管理",
        permit: 1,
        component: () => import('@/views/education-train/config/index'),
        icon: "icon-shouye2",
        meta: { title: '机构管理', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/education-train/order',
        name: 'education-train/order',
        web_name: "报名订单管理",
        permit: 1,
        component: () => import('@/views/education-train/order/index'),
        icon: "icon-shouye2",
        meta: { title: '报名订单管理', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/education-train/refund',
        name: 'education-train/refund',
        web_name: "退款管理",
        permit: 1,
        component: () => import('@/views/education-train/refund/index'),
        icon: "icon-shouye2",
        meta: { title: '退款管理', icon: 'dashboard', noCache: true, affix: true },
      },
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/holiday-travel',
    component: Layout,
    permit: 1,
    web_name: '假日出游',
    children: [
      {
        path: '/holiday-travel/config',
        permit: 1,
        component: (resolve) => require(['@/views/holiday-travel/config/index'], resolve),
        web_name: '出游配置',
        meta: { title: '出游配置' },
      },
      {
        path: '/holiday-travel/project-category',
        permit: 1,
        web_name: '项目类型',
        component: (resolve) => require(['@/views/holiday-travel/project-category/index'], resolve),
        meta: { title: '项目类型' },
      },
      {
        path: '/holiday-travel/project',
        permit: 1,
        component: (resolve) => require(['@/views/holiday-travel/project/index'], resolve),
        web_name: '出游项目',
        meta: { title: '出游项目' },
      }
    ]
  },
  {
    path: '/team-building-activities',
    icon: "icon-shouye2",
    web_name: "团建活动",
    component: Layout,
    permit: 1,
    children: [
      {
        path: '/team-building-activities/config',
        name: 'team-building-activities/config',
        web_name: "团建活动",
        permit: 1,
        component: () => import('@/views/team-building-activities/config/index'),
        icon: "icon-shouye2",
        meta: { title: '团建活动', icon: 'dashboard', noCache: true, affix: true },
      }
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/substitution-express',
    component: Layout,
    permit: 1,
    web_name: '代取快递',
    children: [
      {
        path: '/substitution-express/config',
        permit: 1,
        component: (resolve) => require(['@/views/substitution-express/config/index'], resolve),
        web_name: '快递配置',
        meta: { title: '快递配置' },
      },
      {
        path: '/substitution-express/order',
        permit: 1,
        web_name: '代取订单',
        component: (resolve) => require(['@/views/substitution-express/order/index'], resolve),
        meta: { title: '代取订单' },
      },
      {
        path: '/substitution-express/deliveryer',
        permit: 1,
        component: (resolve) => require(['@/views/substitution-express/deliveryer/index'], resolve),
        web_name: '配送员列表',
        meta: { title: '配送员列表' },
      },
      {
        path: '/substitution-express/take-profit',
        permit: 1,
        web_name: '代取收益',
        component: (resolve) => require(['@/views/substitution-express/take-profit/index'], resolve),
        meta: { title: '代取收益' },
      }
    ]
  },
  {
    path: '/full-time-recruitment',
    icon: "icon-shouye2",
    web_name: "全职招聘",
    component: Layout,
    permit: 1,
    children: [
      {
        path: '/full-time-recruitment/config',
        name: 'full-time-recruitment/config',
        web_name: "全职招聘",
        permit: 1,
        component: () => import('@/views/full-time-recruitment/config/index'),
        icon: "icon-shouye2",
        meta: { title: '全职招聘', icon: 'dashboard', noCache: true, affix: true },
      }
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/bole-business-school',
    component: Layout,
    permit: 1,
    web_name: '伯乐商学院',
    children: [
      {
        path: '/bole-business-school/config',
        permit: 1,
        component: (resolve) => require(['@/views/bole-business-school/config/index'], resolve),
        web_name: '商学院配置',
        meta: { title: '商学院配置' },
      },
      {
        path: '/bole-business-school/article-category',
        permit: 1,
        web_name: '文章类型',
        component: (resolve) => require(['@/views/bole-business-school/article-category/index'], resolve),
        meta: { title: '文章类型' },
      },
      {
        path: '/bole-business-school/article',
        permit: 1,
        component: (resolve) => require(['@/views/bole-business-school/article/index'], resolve),
        web_name: '文章管理',
        meta: { title: '文章管理' },
      },
      {
        path: '/bole-business-school/comment-audit',
        permit: 1,
        web_name: '评论审核',
        component: (resolve) => require(['@/views/bole-business-school/comment-audit/index'], resolve),
        meta: { title: '文章类型' },
      }
    ]
  },
  {
    icon: 'icon-caidan',
    path: '/merchant',
    component: Layout,
    permit: 1,
    web_name: '商家管理',
    children: [
      {
        path: '/merchant/notice',
        permit: 1,
        component: (resolve) => require(['@/views/merchant/notice/index'], resolve),
        web_name: '商户公告',
        meta: { title: '商户公告' },
      },
      {
        path: '/merchant/manage',
        permit: 1,
        web_name: '商户管理',
        component: (resolve) => require(['@/views/merchant/manage/index'], resolve),
        meta: { title: '商户管理' },
      },
      {
        path: '/merchant/withdrawal',
        permit: 1,
        component: (resolve) => require(['@/views/merchant/withdrawal/index'], resolve),
        web_name: '商户提现',
        meta: { title: '商户提现' },
      }
    ]
  },
  {
    path: '/financial',
    icon: "icon-shouye2",
    web_name: "财务管理",
    component: Layout,
    permit: 1,
    children: [
      {
        path: '/financial/config',
        name: 'financial/config',
        web_name: "财务管理",
        permit: 1,
        component: () => import('@/views/financial/config/index'),
        icon: "icon-shouye2",
        meta: { title: '财务管理', icon: 'dashboard', noCache: true, affix: true },
      },
      {
        path: '/driver-school/paid',
        permit: 1,
        web_name: '驾考财务管理',
        component: (resolve) => require(['@/views/driver-school/paid/index'], resolve),
        meta: { title: '驾考财务管理' },
      },
    ]
  },
  {
    icon: 'icon-shezhi1',
    path: '/system-set',
    component: Layout,
    permit: 1,
    web_name: '系统设置',
    children: [
      {
        path: '/system-set/config-index',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/config-index'], resolve),
        web_name: '首页配置',
        meta: { title: '首页配置' },
      },
      {
        path: '/system-set/config-system',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/config-system'], resolve),
        web_name: '系统配置',
        meta: { title: '系统配置' },
      },
      {
        path: '/system-set/contact-set',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/contact-set'], resolve),
        web_name: '合同管理',
        meta: { title: '合同管理' },
      },
      {
        path: '/system-set/delivery-rule',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/delivery-rule/index'], resolve),
        web_name: '运费规则',
        meta: { title: '运费规则' },
      },
      {
        path: '/system-set/free-post',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/free-post/index'], resolve),
        web_name: '包邮规则',
        meta: { title: '包邮规则' },
      },
      {
        path: '/system-set/config-web',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/config-web'], resolve),
        web_name: '前端配置',
        meta: { title: '前端配置' },
      },
      {
        path: '/system-set/role',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/role'], resolve),
        web_name: '角色管理',
        meta: { title: '角色管理' },
      },
      {
        path: '/system-set/account',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/account'], resolve),
        web_name: '账号管理',
        meta: { title: '账号管理' },
      },
      // {
      //   path: '/system-set/resident',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/system-set/resident'], resolve),
      //   web_name: '驻厂管理',
      //   meta: { title: '驻厂管理' },
      // },
      // {
      //   path: '/system-set/education',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/system-set/education'], resolve),
      //   web_name: '教育运营人员管理',
      //   meta: { title: '教育运营人员管理' },
      // },
      // {
      //   path: '/system-set/driver',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/system-set/driver'], resolve),
      //   web_name: '教练管理',
      //   meta: { title: '教练管理' },
      // },
      // {
      //   path: '/system-set/driving-school-user',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/system-set/driving-school-user'], resolve),
      //   web_name: '驾校运营人员管理',
      //   meta: { title: '驾校运营人员管理' },
      // },
      // {
      //   path: '/system-set/menu',
      //   permit: 1,
      //   component: (resolve) => require(['@/views/system-set/menu/index'], resolve),
      //   web_name: '菜单管理',
      //   meta: { title: '菜单管理' },
      // },
      {
        path: '/system-set/article',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/article'], resolve),
        web_name: '系统文章',
        meta: { title: '系统文章' },
      },
      {
        path: '/system-set/log',
        permit: 1,
        component: (resolve) => require(['@/views/system-set/log'], resolve),
        web_name: '系统日志',
        meta: { title: '系统日志' },
      },
    ]
  },
  {
    path: '/404',
    permit: 0,
    component: (resolve) => require(['@/views/error/404'], resolve)
  },
  {
    path: '/401',
    permit: 0,
    component: (resolve) => require(['@/views/error/401'], resolve)
  },
]

export default new VueRouter({
  base: process.env.BASE_URL,
  routes: constantRoutes
})

